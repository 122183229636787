import { Vue, Component } from "vue-property-decorator";
import JsonExcel from "vue-json-excel";
import reporte from "@/components/reporte.vue";
import { getUrl, ApiList } from "@/config/apiUrls";
import { EstatusPago, Kiosko, Operacion } from "apd.models";
import { mdiClose, mdiCalendarExport } from "@mdi/js";
import moment from "moment";
import { ApiSistemaPagos, AxiosHttpClient } from "apd.apiconnectors";
import { ReporteCND } from "@/models/ReporteCND";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  props: {
    date: {
      type: String,
    },
    date2: {
      type: String,
    },
    headers: {
      type: Array,
      required: true,
    },
    formDataVista: {
      type: Array,
      required: true,
    },
    nombreReporte: {
      type: String,
      default: "Reporte",
    },
    cajerosArr: {
      type: Array,
      required: true,
    },
    showEmptyOps: {
      type: Boolean,
      required: true,
    },
    jsonFields: {
      type: Object,
      required: true,
    },
  },
  components: {
    reporte,
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    toDate(value: string): string {
      if (value) {
        return moment(String(value)).format("DD/MM/YY");
      } else {
        return "";
      }
    },
  },
})
export default class CambiosNoDispensadosTable extends Vue {
  public IconClose: string = mdiClose;
  public IconExport: string = mdiCalendarExport;
  public dataExcel = [] as ReporteCND[];
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public get reporteNombre(): string {
    return "reporteCajeros" + this.$props.date + "/" + this.$props.date2 + ".xls";
  }

  public get saldo() {
    return (operacionPpal: Operacion): number => this.getSaldo(operacionPpal);
  }

  public get fecha() {
    return (fechaStr: string): Date => this.getFecha(fechaStr);
  }

  public get fechaRel() {
    return (operacion: Operacion): Date | undefined => this.getFechaRel(operacion);
  }

  public get noDispensados() {
    return (operacion: Operacion): number => this.getNoDispensadosFromOperacion(operacion);
  }

  public get dispensados() {
    return (operacion: Operacion): number => this.getDispensadosFromOperacionRel(operacion);
  }

  public get nombreCajero() {
    return (id: string): string => this.getNombreCajero(id);
  }

  public get nombreCajeroRel() {
    return (operacion: Operacion): string => this.getNombreCajeroRel(operacion);
  }

  private getFecha(fechaStr: string) {
    return new Date(new Date(fechaStr).toDateString());
  }

  private getFechaRel(operacion: Operacion) {
    const relacionada = this.getRelacionadaMasReciente(operacion);
    if (relacionada) {
      return new Date(new Date(relacionada.created).toDateString());
    }
  }

  private getNombreCajero(id: string) {
    const cajero = (this.$props.cajerosArr as Kiosko[]).find((cajero) => cajero.id === id);
    if (cajero) {
      return cajero.nombreReportes;
    }
    return "";
  }

  private getNombreCajeroRel(operacion: Operacion) {
    const relacionada = this.getRelacionadaMasReciente(operacion);

    if (relacionada) {
      const cajero = (this.$props.cajerosArr as Kiosko[]).find((cajero) => cajero.id === relacionada.idKiosko);
      if (cajero) {
        return cajero.nombreReportes;
      }
    }
    return "";
  }

  private getNoDispensadosFromOperacion(operacion: Operacion) {
    if (operacion.pagos) {
      const opPagos = operacion.pagos.filter((pago) => pago.estatusPago === EstatusPago.NoDispensado);
      return opPagos.reduce((r, i) => r + Number(i.monto), 0);
    }
    return 0;
  }

  private getDispensadosFromOperacionRel(operacion: Operacion) {
    const relacionada = this.getRelacionadaMasReciente(operacion);
    if (relacionada) {
      if (relacionada.pagos) {
        const opPagos = relacionada.pagos.filter((pago) => pago.estatusPago === EstatusPago.Dispensado);
        return opPagos.reduce((r, i) => r + Number(i.monto), 0);
      }
    }
    return 0;
  }

  private getDispensadosFromOperacion(operacion: Operacion) {
    let opPagos = 0;
    if (operacion.operacionesRelacionadas) {
      operacion.operacionesRelacionadas.forEach((operacionRelacionada) => {
        if (operacionRelacionada.operacionRelacionada) {
          if (operacionRelacionada.operacionRelacionada.pagos) {
            opPagos += operacionRelacionada.operacionRelacionada.pagos
              .filter((pago) => pago.estatusPago === EstatusPago.Dispensado)
              .reduce((r, i) => r + Number(i.monto), 0);
          }
        }
      });
      return opPagos;
    }
    return 0;
  }

  private getSaldo(operacionPpal: Operacion) {
    const importe = this.getNoDispensadosFromOperacion(operacionPpal);
    const acumulado = Number(this.getDispensadosFromOperacion(operacionPpal));
    return Number(importe) - Number(acumulado);
  }

  public getFechaIni(): string {
    return this.$props.date;
  }

  public getFechaFin(): string {
    return this.$props.date2;
  }

  public customSortDataTable(items: Operacion[], sortBy: string[], sortDesc: boolean[]): Operacion[] {
    items.sort((a, b) => {
      if (sortBy.includes("fechaOperacion")) {
        if (new Date(new Date(a.created).toDateString()) > new Date(new Date(b.created).toDateString()))
          return sortDesc[0] ? -1 : 1;
        else if (new Date(new Date(a.created).toDateString()) < new Date(new Date(b.created).toDateString()))
          return sortDesc[0] ? 1 : -1;
      }
      if (sortBy.includes("nombreCajeroOperacion")) {
        // eslint-disable-next-line
        if (
          this.$props.cajerosArr.find((o: Kiosko) => o.id === a.idKiosko)?.nombreReportes >
          this.$props.cajerosArr.find((o: Kiosko) => o.id === b.idKiosko)?.nombreReportes
        )
          return sortDesc[1] ? -1 : 1;
        // eslint-disable-next-line
        else if (
          this.$props.cajerosArr.find((o: Kiosko) => o.id === a.idKiosko)?.nombreReportes <
          this.$props.cajerosArr.find((o: Kiosko) => o.id === b.idKiosko)?.nombreReportes
        )
          return sortDesc[1] ? 1 : -1;
      }
      if (sortBy.includes("folioAPD")) {
        // eslint-disable-next-line
        if (a.folioAPD!! > b.folioAPD!!) return sortDesc[2] ? -1 : 1;
        // eslint-disable-next-line
        else if (a.folioAPD!! < b.folioAPD!!) return sortDesc[0] ? 1 : -1;
      }
      return 0;
    });
    return items;
  }

  public get fetchExcelData(): ReporteCND[] {
    const dataExcelLocal = [] as ReporteCND[];
    this.$props.formDataVista.forEach((element: Operacion) => {
      if (element.operacionesRelacionadas && element.operacionesRelacionadas.length > 0) {
        const fila = {} as ReporteCND;
        //CND
        if (element.fechaAplicacion) {
          fila.fechaOperacion = this.getFecha(element.fechaAplicacion);
        } else {
          fila.fechaOperacion = this.getFecha(element.created);
        }

        if (element.folioAPD) {
          fila.folioAPD = element.folioAPD;
        } else {
          fila.folioAPD = "";
        }

        fila.nombreCajeroOperacion = this.getNombreCajero(element.idKiosko);

        if (element.pagos) {
          fila.importe = this.getNoDispensadosFromOperacion(element);
        }

        //DND
        fila.nuevoCND = fila.nombreCajeroDND = "";
        fila.saldo = fila.dND = 0.0;
        fila.fechaDND = this.getFechaRel(element) as Date;
        fila.nombreCajeroDND = this.getNombreCajeroRel(element);
        fila.dND = this.getDispensadosFromOperacionRel(element);
        fila.saldo = this.getSaldo(element);
        if (fila.saldo == 0 && !this.$props.showEmptyOps) {
          return;
        }

        dataExcelLocal.push(fila);
      } else {
        // poner || getSaldo(element) > 0 dentro de el if de abajo
        if (this.$props.showEmptyOps || this.getSaldo(element) > 0) {
          const fila = {} as ReporteCND;
          //CND
          if (element.fechaAplicacion) {
            fila.fechaOperacion = this.getFecha(element.fechaAplicacion);
          } else {
            fila.fechaOperacion = this.getFecha(element.created);
          }

          if (element.folioAPD) {
            fila.folioAPD = element.folioAPD;
          } else {
            fila.folioAPD = "";
          }

          fila.nombreCajeroOperacion = this.getNombreCajero(element.idKiosko);

          if (element.pagos) {
            fila.importe = this.getNoDispensadosFromOperacion(element);
          }

          //DND
          fila.nuevoCND = fila.nombreCajeroDND = "";
          fila.saldo = fila.dND = 0.0;
          fila.nombreCajeroDND = "";
          fila.saldo = fila.importe;
          fila.nuevoCND = "";

          dataExcelLocal.push(fila);
        }
      }
      this.dataExcel = [...dataExcelLocal].sort((a, b) => {
        if (a.fechaOperacion > b.fechaOperacion) return 1;
        else if (a.fechaOperacion < b.fechaOperacion) return -1;

        if (a.nombreCajeroOperacion > b.nombreCajeroOperacion) return 1;
        else if (a.nombreCajeroOperacion < b.nombreCajeroOperacion) return -1;

        if (a.folioAPD > b.folioAPD) return 1;
        else if (a.folioAPD < b.folioAPD) return -1;

        return 0;
      });
    });
    return this.dataExcel;
  }

  private getRelacionadaMasReciente(operacionPpal: Operacion): Operacion | undefined {
    if (operacionPpal.operacionesRelacionadas) {
      const relacionadas = [...operacionPpal.operacionesRelacionadas];
      relacionadas.sort((a, b) => {
        if (a.created > b.created) return 1;
        else if (a.created < b.created) return -1;
        return 0;
      });
      return operacionPpal.operacionesRelacionadas[0].operacionRelacionada;
    }
  }
}
