import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import Dropdowns from "@/store/modules/dropdownModule";
import JsonExcel from "vue-json-excel";
import { mdiTableSearch, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline } from "@mdi/js";
import reporte from "@/components/reporte.vue";
import { ApiSistemaPagos, AxiosHttpClient } from "apd.apiconnectors";
import { getUrl, ApiList } from "@/config/apiUrls";
import { Rules } from "@/models/rules";
import { Municipio, Kiosko, Filtro } from "apd.models";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  props: {
    nombreReporte: {
      type: String,
      required: true,
      default: "Reporte",
    },
    buscarFunc: {
      type: Function,
      required: true,
    },
    showFolioAPD: {
      type: Boolean,
      default: false,
    },
    showCNDSwitch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    reporte,
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
})
export default class CajerosFormComponent extends Vue {
  public IconTSearch: string = mdiTableSearch;
  public IconCloseCh: string = mdiCloseBox;
  public IconMinusCh: string = mdiMinusBox;
  public IconCheckbox: string = mdiCheckboxBlankOutline;
  public tzoffset = new Date().getTimezoneOffset() * 60000;
  public date = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public date2 = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public menu = false;
  public menu2 = false;
  public resumen = false;
  public mensaje = "Todos los cajeros";
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };
  public mostrarFolioApd = this.$props.showFolioAPD;
  public nombre = this.$props.nombreReporte;

  public cajerosArr = [] as Kiosko[];
  public loadCajero = false;
  public municipiosArr = [] as Municipio[];
  public formFiltro: Filtro = {
    rfc: undefined,
    email: undefined,
    referenciaPago: undefined,
    folioAutorizacion: undefined,
    folioAPD: undefined,
    telefono: undefined,
    limitar: undefined,
    autorizado: undefined,
    idEmpresa: undefined,
    idServicio: undefined,
    idFormaPago: undefined,
    municipios: [],
    estatusOperacion: [2, 3, 8],
    kioskos: [],
    fechaInicial: "",
    fechaFinal: "",
  };

  public async mounted(): Promise<void> {
    Spinner.Show();
    //await this.cajeros();

    await Dropdowns.GetMunicipios().then(() => {
      this.municipiosArr = Dropdowns.ListaMunicipios() as Municipio[];
      this.municipiosArr.push({
        id: 0,
        idEstado: 0,
        nombre: "Internet",
        created: Date.now().toString(),
        updated: Date.now().toString(),
      });
      this.municipiosArr.sort((a, b) => a.nombre.localeCompare(b.nombre));
    });

    Spinner.Hide();
  }

  public get loading(): boolean {
    return Spinner.showLoader;
  }

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public async cajeros(): Promise<void> {
    this.loadCajero = true;
    this.formFiltro.kioskos = [];
    if (this.formFiltro.municipios) {
      Dropdowns.GetKioskosMunicipio(this.formFiltro.municipios).then(() => {
        this.cajerosArr = (Dropdowns.ListaKioskos() as Kiosko[]).sort((a, b) =>
          a.nombreReportes.localeCompare(b.nombreReportes),
        );
        this.cajerosArr = this.cajerosArr.filter(
          (o) =>
            o.mostrarReportes ||
            (o.ubicacion == "WEB" && !o.nombreReportes.startsWith("Caj") && !o.nombreReportes.endsWith("Ext")),
          /*
              o.nombreReportes == "PD.com" ||
              o.nombreReportes == "PdApp" ||
              o.nombreReportes == "Pd.comJmasJuarez",
              */
        );
        const user = this.$store.getters["oidcStore/oidcUser"];
        if (user["user_type"] != "Empleado") {
          this.cajerosArr = this.cajerosArr.filter((c) => c.idEmpresa == user["RSP.Empresa"]);
          this.formFiltro.kioskos?.push(this.cajerosArr[0].id);
        } else {
          this.cajerosArr.forEach((c) => {
            this.formFiltro.kioskos?.push(c.id);
          });
        }
      });
    }
    this.loadCajero = false;
  }

  public icon(): string {
    if (this.formFiltro.kioskos != undefined) {
      if (this.formFiltro.kioskos.length == 0) {
        this.mensaje = "Todos los Cajeros";
        return this.IconMinusCh;
      }
      if (this.formFiltro.kioskos.length > 0) {
        this.mensaje = "Todos los Cajeros";
        return this.IconCheckbox;
      }
    }
    this.mensaje = "Seleccione Cajeros";
    return this.IconCloseCh;
  }

  public iconMunicipios(): string {
    if (this.formFiltro.municipios && this.formFiltro.municipios?.length <= 0) {
      return this.IconMinusCh;
    }
    if (this.formFiltro.municipios && this.formFiltro.municipios?.length > 0) {
      return this.IconCheckbox;
    }
    return this.IconCloseCh;
  }

  public toggleMunicipios(): void {
    if (this.formFiltro.municipios?.length === this.municipiosArr.length) {
      this.formFiltro.municipios = [];
    } else {
      this.formFiltro.municipios = this.municipiosArr.map((municipio) => {
        return municipio.id;
      });
    }
    this.cajeros();
  }

  public toggle(): void {
    if (this.formFiltro.kioskos?.length === this.cajerosArr.length) {
      this.formFiltro.kioskos = [];
    } else {
      this.formFiltro.kioskos = this.cajerosArr.map((cajero) => {
        return cajero.id;
      });
    }
    this.mensaje = "Todos los Cajeros";
  }

  public buscar(): void {
    this.formFiltro.fechaInicial = this.date;
    this.formFiltro.fechaFinal = this.date2 + " " + "23:59:59";

    if (this.formFiltro.kioskos && this.formFiltro.kioskos.length <= 0) {
      const user = this.$store.getters["oidcStore/oidcUser"];
      if (user["user_type"] != "Empleado") {
        this.formFiltro.kioskos = this.cajerosArr.map((cajero) => {
          return cajero.id;
        });
      }
    }
    this.$props.buscarFunc(this.formFiltro);
  }

  public onSwitchChanged(): void {
    this.$emit("switchChanged");
  }
}
