
import { Vue, Component, Prop } from "vue-property-decorator";
import { ReporteCajeros } from "../models/ReporteCajeros";
import { mdiCalendarExport } from "@mdi/js";
import { TableHeader } from "@/models/vuetifyModels";

@Component
export default class ReporteComponent extends Vue {
  public IconExport: string = mdiCalendarExport;

  @Prop(String) public readonly date!: string;
  @Prop(String) public readonly date2!: string;
  @Prop(Number) public readonly totalPagos!: number;
  @Prop(Number) public readonly totalImporte!: number;
  @Prop(Array) public readonly formData: ReporteCajeros[] = [];
  @Prop(Array) public readonly headers: TableHeader[] = [];

  public formatFecha(date: string): string {
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}/${month}/${year}`;
  }
  public toMoney(value: number): string {
    if (typeof value !== "number") {
      return value;
    }
    return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
}
