import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import Snackbar from "@/store/modules/errorSnackbar";
import JsonExcel from "vue-json-excel";
import { TableHeader } from "@/models/vuetifyModels";
import reporte from "@/components/reporte.vue";
import { ApiSistemaPagos, ApiErrorResponse, AxiosHttpClient } from "apd.apiconnectors";
import { getUrl, ApiList } from "@/config/apiUrls";
import { Kiosko, Filtro, Operacion } from "apd.models";
import cajerosForm from "@/components/forms/cajerosForm.vue";
import Dropdowns from "@/store/modules/dropdownModule";
import cambiosNoDispensadosTable from "@/components/tables/cambiosNoDispensadosTable.vue";
import { mdiClose } from "@mdi/js";
import { ToDate } from "@/filters";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  components: {
    reporte,
    cajerosForm,
    cambiosNoDispensadosTable,
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
})
export default class ReporteCajerosComponent extends Vue {
  public date = "";
  public date2 = "";
  public IconClose: string = mdiClose;
  public dialog = false;
  public showEmptyOps = true;
  public cajerosArr = [] as Kiosko[];
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];

  public json_fields = {
    Fecha: {
      field: "fechaOperacion",
      callback: (value: Date): string => {
        return ToDate(value.toString());
      },
    },
    FolioAPD: "folioAPD",
    Cajero: "nombreCajeroOperacion",
    Importe: {
      field: "importe",
      callback: (value: number): string => {
        if (value) return "$" + value.toFixed(2);
        else return "$" + (0 as number).toFixed(2);
      },
    },
    FechaDND: {
      field: "fechaDND",
      callback: (value: Date): string => {
        return ToDate(value.toString());
      },
    },
    CajeroDND: "nombreCajeroDND",
    DND: {
      field: "dND",
      callback: (value: number): string => {
        if (value) return "$" + value.toFixed(2);
        else return "$" + (0 as number).toFixed(2);
      },
    },
    Saldo: {
      field: "saldo",
      callback: (value: number): string => {
        if (value) return "$" + value.toFixed(2);
        else return "$" + (0 as number).toFixed(2);
      },
    },
  };
  public headers: TableHeader[] = [
    {
      text: "Fecha",
      value: "fechaOperacion",
      align: "center",
      sortable: true,
    },
    {
      text: "Folio APD",
      value: "folioAPD",
      align: "center",
      sortable: true,
    },
    {
      text: "Cajero",
      value: "nombreCajeroOperacion",
      align: "center",
      sortable: true,
    },
    {
      text: "Importe",
      value: "importe",
      align: "center",
      sortable: false,
    },
    {
      text: "Fecha",
      value: "fechaDND",
      align: "center",
      sortable: false,
    },
    {
      text: "Cajero",
      value: "nombreCajeroDND",
      align: "center",
      sortable: false,
    },
    {
      text: "DND",
      value: "dND",
      align: "center",
      sortable: false,
    },
    {
      text: "Saldo",
      value: "saldo",
      align: "center",
      sortable: false,
    },
  ];

  public formDataVista: Operacion[] = [];

  public get loading(): boolean {
    return Spinner.showLoader;
  }

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public async cajeros(): Promise<void> {
    //if ((Dropdowns.ListaKioskos() as Kiosko[]).length == 0) {
    Dropdowns.GetKioskos()
      .then(() => {
        this.cajerosArr = Dropdowns.ListaKioskos() as Kiosko[];

        const user = this.$store.getters["oidcStore/oidcUser"];
        if (user["user_type"] != "Empleado") {
          this.cajerosArr = this.cajerosArr.filter((c) => c.idEmpresa == user["RSP.Empresa"]);
        }
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        Snackbar.Show(err.apiError.mensajeUsuario);
        Spinner.Hide();
      });
  }

  public async buscar(formFiltro: Filtro): Promise<void> {
    Spinner.Show();
    if (formFiltro.fechaInicial) {
      this.date = formFiltro.fechaInicial;
    }

    if (formFiltro.fechaFinal) {
      this.date2 = formFiltro.fechaFinal;
    }
    //Este if y su contenido es una mexicanada borrar despues de actualizar el API de Sistema Pagos a una version mas nueva de .net
    if (formFiltro.kioskos?.length == 1) {
      formFiltro.kioskos?.push(formFiltro.kioskos[0]);
    }

    await this.clienteSP()
      .OperacionesCNDFiltro(formFiltro)
      .then((resp) => {
        //Este if y su contenido es una mexicanada borrar despues de actualizar el API de Sistema Pagos a una version mas nueva de .net
        if (formFiltro.kioskos?.length == 2) {
          if (formFiltro.kioskos[0] === formFiltro.kioskos[1]) {
            formFiltro.kioskos?.pop();
          }
        }

        //Este sort es un escape rápido para el problema de ordenamiento, hay que encontrar la forma de ordenarlo en el datatable
        this.formDataVista = resp as unknown as Operacion[];

        this.dialog = true;
        Spinner.Hide();
      })
      .catch((resp) => {
        //Este if y su contenido es una mexicanada borrar despues de actualizar el API de Sistema Pagos a una version mas nueva de .net
        if (formFiltro.kioskos?.length == 2) {
          if (formFiltro.kioskos[0] === formFiltro.kioskos[1]) {
            formFiltro.kioskos?.pop();
          }
        }
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        this.formDataVista = [];
        try {
          Snackbar.Show(err.apiError.mensajeUsuario);
        } catch (err) {
          Snackbar.Show("Ocurrio un error interno");
        }
        Spinner.Hide();
      });
  }

  public onSwitchChanged(): void {
    this.showEmptyOps = !this.showEmptyOps;
  }

  public mounted(): void {
    this.cajeros();
  }
}
